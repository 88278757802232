import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

//Components
import Layout from '../components/layout'

//Styles
import * as hero from '../styles/Hero.module.scss'
import * as styles from '../styles/Calendar.module.scss'

const Calendar = ({ pageContext: { locale }, data }) => {
  return (
    <Layout locale={locale}>
      <div className={hero.heroImg}>
        <GatsbyImage
          className={hero.fixedHeight}
          alt=""
          image={data.file.childImageSharp.gatsbyImageData}
        />
        <div className={hero.searchField}>
          <h1>
            <FormattedMessage id="calendar_headline" />
          </h1>
        </div>
      </div>
      <div className={styles.row}>
        <div className={`container ${styles.container}`}>
          <FormattedHTMLMessage id="calendar_p" />
        </div>
      </div>
    </Layout>
  )
}

Calendar.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default Calendar

export const calendarQuery = graphql`
  query pictureQuery {
    file(relativePath: { eq: "background-cal.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, height: 400, placeholder: BLURRED)
      }
    }
  }
`
